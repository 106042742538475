<template>
  <Layout class="GoodsItemCreateOrEdit">
    <h4 style="padding-left: 80px;">
      基本信息
    </h4>
    <el-form
      ref="formData"
      :model="formData"
      label-width="150px"
      label-position="right"
      style="width: 520px;"
      :rules="rules"
    >
      <el-form-item
        label="广告名称"
        prop="name"
      >
        <el-input
          v-model="formData.name"
          clearable
          placeholder="请输入广告名称"
        />
      </el-form-item>
      <el-form-item
        label="广告ID"
        prop="admob_id"
      >
        <el-input
          v-model="formData.admob_id"
          clearable
          placeholder="请输入广告ID"
        />
      </el-form-item>

      <el-form-item
        label="应用ID"
        prop="app_key"
      >
        <el-select
          v-model="formData.app_key"
          placeholder="选择APP"
          clearable
        >
          <el-option
            v-for="(item, key) in appKeysNameMap"
            :key="key"
            :label="item"
            :value="key"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="来源标记"
        prop="remark"
      >
        <el-input
          v-model="formData.remark"
          clearable
          placeholder="请输入来源标记"
        />
      </el-form-item>
      <el-form-item
        label="广告描述"
        prop="description"
      >
        <el-input
          v-model="formData.description"
          type="textarea"
          placeholder="请输入广告描述"
        />
      </el-form-item>
      <el-form-item
        label="排序"
        prop="rank"
      >
        <el-input
          v-model="formData.rank"
          clearable
          placeholder="请输入排序"
        />
      </el-form-item>

      <el-form-item
        label="奖励配置（金币）"
        prop="gold"
      >
        <el-input-number
          v-model="formData.gold"
          :min="1"
          label="单行输入"
        />
      </el-form-item>
      <el-form-item
        label="获取限制/天:"
        prop="limit"
      >
        <el-input
          v-model="formData.limit"
          placeholder="周期内可获取次数上限"
        >
          <el-select
            slot="append"
            v-model="formData.limit_type"
            placeholder="每天"
            style="width:100px"
          >
            <el-option
              label="每天"
              :value="1"
            />
          </el-select>
        </el-input>
      </el-form-item>
      <!-- <el-form-item
        label="有效期配置/天:"
        prop="expiry_days"
      >
        <el-input
          v-model="formData.expiry_days"
          placeholder="奖励过期时间设置"
        >
          <el-select
            slot="append"
            v-model="formData.expiry_days_type"
            placeholder="天"
            style="width:100px"
          >
            <el-option
              label="天"
              :value="1"
            />
          </el-select>
        </el-input>
      </el-form-item> -->
      <el-form-item
        label="防盗刷设置/分钟:"
        prop="interval"
      >
        <el-input
          v-model="formData.interval"
          placeholder="每次任务领取间隔"
        >
          <el-select
            slot="append"
            v-model="formData.interval_type"
            placeholder="分钟"
            style="width:100px"
          >
            <el-option
              label="分钟"
              :value="1"
            />
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item
        label="广告显示国家:"
      >
        <el-select
          v-model="formData.region_type"
        >
          <el-option
            label="全部国家显示"
            value="all"
          />
          <el-option
            label="部分国家显示"
            value="display"
          />
          <el-option
            label="部分国家隐藏"
            value="hide"
          />
        </el-select>
      </el-form-item>
      <div v-if="formData.region_type != 'all'">
        <el-form-item
          label="指定国家配置:"
          prop="region_code"
        >
          <el-input
            v-model="formData.region_code"
            type="textarea"
            placeholder="请输入国家代码，多个国家用“|”分隔"
          />
        </el-form-item>
      </div>

      <el-form-item
        label="状态:"
        prop="status"
      >
        <el-switch
          v-model="formData.status"
          :active-value="1"
          :inactive-value="0"
          active-text="启用"
          inactive-text="禁用"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="addLoading"
          type="primary"
          @click="onSubmit()"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import {
  createActivityTasks,
  updateActivityTasks
} from '@/api/incentive-advertising.js'
import { commonRequestGet } from '@/api/commonApi.js'
export default {
  components: {

  },
  data () {
    return {
      row: '',
      formData: {
        limit_type: 1, // 获取限制
        expiry_days_type: 1, // 有效期配置
        interval_type: 1, // 防盗刷设置
        gold: '1',
        region_type: 'all'
      },
      appKeysNameMap: [],
      addLoading: false,
      rules: {
        name: [{ required: true, message: '必填' }],
        admob_id: [{ required: true, message: '必填' }],
        remark: [{ required: true, message: '必填' }],
        description: [{ required: true, message: '必填' }],
        rank: [{ required: true, message: '必填' }],
        gold: [{ required: true, message: '必填' }],
        limit: [{ required: true, message: '必填' }],
        expiry_days: [{ required: true, message: '必填' }],
        interval: [{ required: true, message: '必填' }],
        app_key: [{ required: true, message: '必填' }]
      }
    }
  },
  async created () {
    this.getAppKeys()
    this.row = this.$route.query.row
    if (this.row && this.row.id) {
      this.formData = this.$route.query.row
    }

    console.log(this.formData) // 输出row
  },

  methods: {
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      console.log(this.appKeysList, '-------')
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
      console.log(this.appKeysNameMap, '-------')
    },

    // 保存
    onSubmit () {
      try {
        this.addLoading = true
        this.$refs.formData.validate(valid => {
          if (valid) {
            if (this.row && this.row.id) {
              const id = this.row.id
              console.log(id, '1111111')
              updateActivityTasks(id, { ...this.formData })
              this.$router.go(-1)
              this.$message.success('编辑成功')
            } else {
              createActivityTasks({ ...this.formData })
              this.$router.go(-1)
              this.$message.success('创建成功')
            }
          }
        })
      } catch (error) {
        this.$message.error(error.response.data.message)
      } finally {
        this.addLoading = false
      }
    }

  }
}
</script>

  <style scoped lang="scss">

  </style>
